import React, { useState, useEffect, useRef } from "react"

import OpenIcon from "../assets/icons/Open.jsx"

const Accordion = ({ content }) => {
  const revealedRef = useRef(null)
  const [open, setOpen] = useState(false)
  useEffect(() => {
    const revealedHeight = revealedRef.current.getBoundingClientRect().height
    // get height, add to div as a data-attr
    // set max-height: 0;
    // then use css to get the max height from data
  })

  return (
    <div className={`accordion ${open ? "open" : ""}`}>
      <div
        className="accordion-question-wrap"
        onClick={() => {
          setOpen(!open)
          console.log(open)
        }}
      >
        <div className="accordion-question">{content.title}</div>
        <OpenIcon />
      </div>
      <div
        className="revealed-content"
        ref={revealedRef}
        dangerouslySetInnerHTML={{ __html: content.content }}
      ></div>
    </div>
  )
}

export default Accordion
