import React from "react";

function InfoIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zM9 4h2v2H9V4zm3 11H8v-2h1v-3H8V8h3v5h1v2z"></path>
    </svg>
  );
}

export default InfoIcon;
