import React, { Fragment } from "react"
import { createId } from "../../utils/utils"

const TextBlock = ({ content }) => {
  return (
    <div className="page-text-block block" id={content.title ? createId(content.title) : ""}>
      <h2 className="block-title">{content.title}</h2>
      {content.text && (
        <div
          className="text"
          dangerouslySetInnerHTML={{ __html: content.text }}
        />
      )}
    </div>
  )
}

export default TextBlock
