import React from 'react';
 
const TextElementBlock = ({content}) => {
  return (
    <div className="page-text-element-block block">
      {content.content && <div className="text-element-block" dangerouslySetInnerHTML={{ __html: content.content }}></div>}
    </div>
  );
}
 
export default TextElementBlock;
