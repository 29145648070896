import React, { useState } from 'react';

import Accordion from "../accordion.js";

const FaqsBlock = ({content}) => {
  const [open, setOpen] = useState(false);
  return (
    
    <div className="faqs-block block">
      {content.questions && content.questions.map((accordion, key) => {
        return (

          <Accordion key={key} content={accordion}/>
        )
      } )}
    </div>
  );
}

export default FaqsBlock;
