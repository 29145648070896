import React from "react"

const TextColumnsBlock = ({ content }) => {
  return (
    <div className="text-column-block block">
      {content.title && <h2 className="block-title">{content.title}</h2>}
      <div className="column-block-inner">
        {content.text_column &&
          content.text_column.map((snippet, key) => {
            console.log(snippet)
            return (
              <div className="column" key={key}>
                {snippet.icon && (
                  <div className="column-icon">
                    <img
                      src={process.env.GATSBY_ROOT_URL + snippet.icon.url}
                    />
                  </div>
                )}
                {snippet.title && (
                  <div className="column-title">{snippet.title}</div>
                )}
                {snippet.text && (
                  <div
                    className="column-text"
                    dangerouslySetInnerHTML={{ __html: snippet.text }}
                  ></div>
                )}
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default TextColumnsBlock
