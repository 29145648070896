import React, { useState, useEffect } from "react"
import Expand from "../../assets/icons/Expand"

const ImageBlock = ({ content }) => {
  const [isUML_image, setIsUML_image] = useState(false)

  useEffect(() => {
    if (content.UML) {
      setIsUML_image(true)
    }
  }, [])

  return (
    <div className="image-block block">
      {!isUML_image && content.image.formats && (
        <img
          sizes="(max-width: 500px) 500px, (max-width: 1000px) 750px, 1500px"
          srcSet={`
          ${process.env.GATSBY_ROOT_URL + content.image.formats.small.url} 500w,
          ${
            process.env.GATSBY_ROOT_URL + content.image.formats.medium.url
          } 1000w,
          ${
            process.env.GATSBY_ROOT_URL + content.image.formats.large.url
          } 1500w,
          `}
        />
      )}
      {!isUML_image && !content.image.formats && content.image.url && (
        <img src={process.env.GATSBY_ROOT_URL + content.image.url} />
      )}
      {isUML_image && !content.image.formats && content.image.url && (
        <div className="uml-wrap">
          <img
            className="uml-image"
            src={process.env.GATSBY_ROOT_URL + content.image.url}
          />
          <div className="gradient" />
          <a className="enlarge btn" href={content.image.url} target="_blank">
            Click to Enlarge
            <Expand />
          </a>
        </div>
      )}

      {content.caption && <div className="caption">{content.caption}</div>}
    </div>
  )
}

export default ImageBlock
