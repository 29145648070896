import React from "react"
import { Link } from "gatsby"

const ColumnsBlock = ({ content }) => {
  return (
    <div className="column-block block">
      {content.title && <h2 className="block-title">{content.title}</h2>}
      <div className="column-block-inner">
        {content.box_column &&
          content.box_column.map((snippet, key) => {
            return (
              <div className="column" key={key}>
                {snippet.title && (
                  <div className="column-title">{snippet.title}</div>
                )}
                {snippet.text && (
                  <div
                    className="column-text"
                    dangerouslySetInnerHTML={{ __html: snippet.text }}
                  ></div>
                )}
                <div className="btn-wrap">
                  {snippet.button && (
                    <Link className="btn" to={snippet.button.link}>
                      {snippet.button.text}
                    </Link>
                  )}
                </div>
              </div>
            )
          })}
      </div>
    </div>
  )
}
export default ColumnsBlock
