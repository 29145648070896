import React from 'react';
import { Link } from 'gatsby';
 
import InfoIcon from "../../assets/icons/Info.jsx";

const AttentionBoxBlock = ({ content }) => {
  return (
  <div className="attention-box-block block">
    <div className="icon-wrap">
      <InfoIcon />
        <div className="text-wrap">
          <div className="title-wrap">
            {content.title && <h4>{content.title}</h4>}
          </div>
        </div>
      </div>
      {content.description && <div className="text" dangerouslySetInnerHTML={{ __html: content.description }} />}

      <div className="btn-wrap">
        {content.button &&
        <Link className="btn" to={content.button.link}>{content.button.text}</Link>}
    </div>
  </div>
  );
}
 
export default AttentionBoxBlock;