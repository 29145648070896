import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

import TextElementBlock from "../components/blocks/TextElementBlock.js"
import IntroBlock from "../components/blocks/IntroBlock"
import AttentionBoxBlock from "../components/blocks/AttentionBoxBlock"
import ImageBlock from "../components/blocks/ImageBlock"

import FaqsBlock from "../components/blocks/FaqsBlock"
import TextBlock from "../components/blocks/TextBlock"
import TextColumnsBlock from "../components/blocks/TextColumnsBlock"
import ColumnsBlock from "../components/blocks/ColumnsBlock"

const Contact = ({ data }) => {
  const contactInfo = data.contactInfo.edges[0].node
  const contactContent = data.contactInfo.edges[0].node.content

  return (
    <div className="getting-started-page">
      <Layout>
        <div className="main-col-one">
          <Seo title={contactInfo.intro.title} />
          <IntroBlock content={contactInfo.intro} />
          {contactContent.map((contentBlock, key) => {
            if (contentBlock.strapi_component === "blocks.text-block") {
              return <TextBlock content={contentBlock} key={key} />
            } else if (
              contentBlock.strapi_component === "blocks.attention-box"
            ) {
              return <AttentionBoxBlock content={contentBlock} key={key} />
            } else if (contentBlock.strapi_component === "elements.image") {
              return <ImageBlock content={contentBlock} key={key} />
            } else if (contentBlock.strapi_component === "elements.text") {
              return <TextElementBlock content={contentBlock} key={key} />
            } else if (contentBlock.strapi_component === "blocks.faq") {
              return <FaqsBlock content={contentBlock} key={key} />
            } else if (
              contentBlock.strapi_component === "blocks.text-columns"
            ) {
              return <TextColumnsBlock content={contentBlock} key={key} />
            } else if (contentBlock.strapi_component === "blocks.box-columns") {
              return <ColumnsBlock content={contentBlock} key={key} />
            }
            return null
          })}
        </div>
      </Layout>
    </div>
  )
}

export default Contact

export const pageQuery = graphql`
  {
    contactInfo: allStrapiContacts {
      edges {
        node {
          intro {
            title
            subtitle
            introduction {
              content
            }
          }
          content
        }
      }
    }
  }
`
