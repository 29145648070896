import React from "react";

function ExpandIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18">
      <path d="M4.5 1.1V0H.6C.3 0 0 .3 0 .6v3.9h1.1V1.9l5.2 5.2.8-.8-5.2-5.2h2.6zM17.4 0h-3.9v1.1h2.6l-5.2 5.2.8.8 5.2-5.2v2.6H18V.6c0-.3-.3-.6-.6-.6zm-.5 16.1l-5.2-5.2-.8.8 5.2 5.2h-2.6V18h3.9c.3 0 .6-.3.6-.6v-3.9h-1.1v2.6zM6.3 10.8L1.1 16v-2.6H0v3.9c0 .4.3.7.6.7h3.9v-1.1H1.9l5.2-5.2-.8-.9z"></path>
    </svg>
  );
}

export default ExpandIcon;